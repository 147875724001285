import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"py-10 d-flex align-center justify-space-between flex-column flex-md-row"},[_c(VImg,{staticClass:"d-block",attrs:{"src":require('@/assets/logo.svg'),"max-width":"220px"}}),_c(VCard,{attrs:{"color":"transparent","max-width":"520px","flat":""}},[_c(VCardText,{staticClass:"custom-color--text text-center text-md-left"},[_vm._v(" Помогаем руководителям автоматизировать цикличные процессы, делаем бизнес более прозрачным и управляемым ")])],1),_c('div',{staticClass:"text-center text-md-right"},[_c(VBtn,{staticClass:"white--text text-sm-h5 pa-0",attrs:{"href":"tel:88619448684","plain":""}},[_vm._v(" 8 (861) 944 8684 ")]),_c('br'),_c(VBtn,{staticClass:"white--text text-subtitle-1 pa-0",attrs:{"href":"mailto:hello@agilo.ru","plain":""}},[_vm._v(" hello@agilo.ru ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }