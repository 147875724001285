<template>
  <v-container class="container__height d-flex flex-column justify-center">
    <h3 class="text-sm-h3 text-center text-md-left">Что мы умеем?</h3>
    <v-list class="d-flex flex-wrap justify-lg-space-between justify-center mt-5 mt-md-16 pa-0">
      <v-card v-for="card in cards" max-width="380px" flat class="pa-5">
        <v-img max-width="60px" :src="card.img"/>
        <v-card-title>
          {{card.title}}
        </v-card-title>
        <v-card-text>
          {{card.desc}}
        </v-card-text>
      </v-card>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "WhatCanDo",
  data() {
    return {
      cards: [
        {
          title: 'Маркетплейсы и сервисы',
          desc: 'Разработка начинается с тестирования ниши, далее MVP и пошаговое усложнение возможностей',
          img: require('../assets/whatcando/migrating.svg')
        },
        {
          title: 'Чатботы',
          desc: 'Автоматизация взаимодействия как с клиентами, так и внутри команды через мессенджеры.',
          img: require('../assets/whatcando/bot.svg')
        },
        {
          title: 'Стартапы',
          desc: 'Мы не просто разработчики, мы глубоко изнутри понимаем задачи и приоритеты стартапов.',
          img: require('../assets/whatcando/startup.svg')
        },
        {
          title: 'Внедрение CRM',
          desc: 'Поможем разобраться с каналами привлечения клиентов, посчитаем юнит-экономику, настроим воронки продаж',
          img: require('../assets/whatcando/customer-relationship-management.svg')
        },
        {
          title: 'Офисная телефония',
          desc: 'Клиенты будут Вам звонить, а вы не будете пропускать этих звонков. Всё просто и удобно',
          img: require('../assets/whatcando/call.svg')
        },
        {
          title: 'IT-консалтинг',
          desc: 'Подберём лучшие решения, внедрим и научим пользоваться, наладим взаимодействие внутри команды',
          img: require('../assets/whatcando/consulting.svg')
        }
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.container__height
  min-height: 100vh
</style>