<template>
  <v-container class="d-flex flex-column justify-space-between py-4 py-md-10 pd-md-16" style="height: 100%">
    <div class="text-right">
      <v-btn class="white--text text-h5 pa-0" href="tel:88619448684" plain>
        8 (861) 944 8684
      </v-btn>
      <br>
      <v-btn class="white--text text-subtitle-1 pa-0" href="mailto:hello@agilo.ru" plain>
        hello@agilo.ru
      </v-btn>
    </div>
    <v-card class="d-flex justify-space-between align-center align-md-end flex-column flex-md-row" color="transparent" flat>
      <v-content>
        <v-img :src="require('@/assets/logo.svg')" max-width="420px" class="mb-9"/>
        <div class="line"></div>
        <v-card-text style="max-width: 400px" class="white--text pl-0 pb-0">
          Помогаем руководителям автоматизировать цикличные процессы, делаем бизнес более прозрачным и управляемым
        </v-card-text>
      </v-content>
<!--      <v-btn color="#1C508D" class="white&#45;&#45;text btn mt-mb-0 mt-8" large>-->
<!--        Написать нам-->
<!--      </v-btn>-->
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped lang="sass">
.line
  max-width: 260px
  width: 100%
  height: 1px
  background-color: #fff
  opacity: .5

.btn
  @media (max-width: 959px)
    width: 100%
    max-width: 400px
</style>
