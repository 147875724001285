import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"container__height d-flex flex-column justify-center"},[_c('h3',{staticClass:"text-sm-h3 text-center text-md-left"},[_vm._v("Что мы умеем?")]),_c(VList,{staticClass:"d-flex flex-wrap justify-lg-space-between justify-center mt-5 mt-md-16 pa-0"},_vm._l((_vm.cards),function(card){return _c(VCard,{staticClass:"pa-5",attrs:{"max-width":"380px","flat":""}},[_c(VImg,{attrs:{"max-width":"60px","src":card.img}}),_c(VCardTitle,[_vm._v(" "+_vm._s(card.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(card.desc)+" ")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }