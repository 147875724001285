<template>
  <v-container class="py-10 d-flex align-center justify-space-between flex-column flex-md-row">
    <v-img :src="require('@/assets/logo.svg')" max-width="220px" class="d-block"/>
    <v-card color="transparent" max-width="520px" flat>
      <v-card-text class="custom-color--text text-center text-md-left">
        Помогаем руководителям автоматизировать цикличные процессы, делаем бизнес более прозрачным и управляемым
      </v-card-text>
    </v-card>
    <div class="text-center text-md-right">
      <v-btn class="white--text text-sm-h5 pa-0" href="tel:88619448684" plain>
        8 (861) 944 8684
      </v-btn>
      <br>
      <v-btn class="white--text text-subtitle-1 pa-0" href="mailto:hello@agilo.ru" plain>
        hello@agilo.ru
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style lang="sass" scoped>
.custom-color--text
  color: #9EA5AA !important
</style>