import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"d-flex flex-column justify-space-between py-4 py-md-10 pd-md-16",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-right"},[_c(VBtn,{staticClass:"white--text text-h5 pa-0",attrs:{"href":"tel:88619448684","plain":""}},[_vm._v(" 8 (861) 944 8684 ")]),_c('br'),_c(VBtn,{staticClass:"white--text text-subtitle-1 pa-0",attrs:{"href":"mailto:hello@agilo.ru","plain":""}},[_vm._v(" hello@agilo.ru ")])],1),_c(VCard,{staticClass:"d-flex justify-space-between align-center align-md-end flex-column flex-md-row",attrs:{"color":"transparent","flat":""}},[_c(VContent,[_c(VImg,{staticClass:"mb-9",attrs:{"src":require('@/assets/logo.svg'),"max-width":"420px"}}),_c('div',{staticClass:"line"}),_c(VCardText,{staticClass:"white--text pl-0 pb-0",staticStyle:{"max-width":"400px"}},[_vm._v(" Помогаем руководителям автоматизировать цикличные процессы, делаем бизнес более прозрачным и управляемым ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }