<template>
  <v-app id="app">

    <v-container fluid class="head">
      <header-component/>
    </v-container>

    <v-container fluid class="body--grey">
      <about-company/>
    </v-container>

    <v-container fluid>
      <what-can-do/>
    </v-container>

    <v-container fluid class="footer">
      <FooterComponent/>
    </v-container>

  </v-app>
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent";
import AboutCompany from "@/components/AboutCompany";
import WhatCanDo from "@/components/WhatCanDo";
import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'App',
  components: {
    FooterComponent,
    WhatCanDo,
    AboutCompany,
    HeaderComponent
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="sass">
*
  box-sizing: border-box

body
  background-color: #000

.body--grey
  background-color: #EEF3F6

.head
  background-image: url("assets/bg.jpg")
  min-height: 100vh

.footer
  background-color: black
</style>