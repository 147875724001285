<template>
  <v-container class="container__height d-flex flex-column justify-center">
    <h3 class="text-sm-h3 text-center text-md-left">О компании</h3>
    <v-list color="transparent" class="d-flex justify-space-between flex-column flex-md-row align-center">
      <v-card max-width="500px" color="transparent" flat>
        <v-card-text class="text-sm-subtitle-1 black--text pl-0">
          Мы - команда молодых и амбициозных экспертов в области разработки и внедрения различных инструментов, автоматизации бизнес-процессов и создания новых it-решений. Умеем и любим работать со стартапами. Разрабатываем веб-сервисы, маркетплейсы, чатботы и прочие крутые штуки.
        </v-card-text>
      </v-card>
      <v-card color="transparent" flat>
        <v-card-text class="text-md-h3 text-h5 black--text">< 15 лет на рынке</v-card-text>
        <v-card-text class="text-md-h3 text-h5 black--text">< 150 проектов</v-card-text>
      </v-card>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "AboutCompany"
}
</script>

<style scoped lang="sass">
.container__height
  min-height: 100vh
</style>